import React, { useEffect, useState } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useLocation } from '@reach/router'; // this helps tracking the location
import CookieConsentDialogStyled from './CookieConsentDialogStyled';
import { BaseComponentProps } from '@/types';

const CookieConsentDialog: React.FC<BaseComponentProps> = () => {
  const location = useLocation();
  const [isVisible, setVisible] = useState(undefined);
  const [isAccepted, setAccepted] = useState(false);
  const [isHidden, setHidden] = useState(false);

  useEffect(() => {
    if (isAccepted === false && isVisible === undefined) {
      setHidden(false);
      setVisible(`show`);
    }

    if (
      Cookies.get(`gatsby-gdpr-cookies-consent`) === `true` &&
      Cookies.get(`accepted`) === `false` &&
      Cookies.get(`decline`) === `false`
    ) {
      setVisible(`show`);
      setHidden(false);
      setAccepted(false);
    } else if (
      Cookies.get(`gatsby-gdpr-cookies-consent`) === `true` &&
      Cookies.get(`accepted`) === undefined &&
      Cookies.get(`decline`) === undefined
    ) {
      setVisible(`byCookieValue`);
      setHidden(true);
      setAccepted(true);
    } else if (
      Cookies.get(`gatsby-gdpr-cookies-consent`) === `true` &&
      Cookies.get(`accepted`) === `true` &&
      Cookies.get(`decline`) === `false`
    ) {
      setVisible(`byCookieValue`);
      setHidden(true);
    }

    if (isVisible === undefined) {
      if (
        Cookies.get(`gatsby-gdpr-cookies-consent`) === `false` &&
        Cookies.get(`accepted`) === `false` &&
        Cookies.get(`decline`) === `true`
      ) {
        setVisible(`hidden`);
        setHidden(true);
        setAccepted(false);
      }
    }
  }, [isHidden, isAccepted, isVisible]);

  useEffect(() => {
    // To work around users who have accepted cookies but didn't have the tagmanager cookie yet
    if (
      !Cookies.get(`gatsby-gdpr-google-tagmanager`) &&
      Cookies.get(`gatsby-gdpr-cookies-consent`) === `true`
    ) {
      Cookies.set(`gatsby-gdpr-google-tagmanager`, `true`);
      initializeAndTrack(location);
    }
  }, [location]);

  const popup = () => (
    <CookieConsentDialogStyled>
      <CookieConsent
        flipButtons
        hideOnAccept={isHidden}
        acceptOnScroll
        acceptOnScrollPercentage={1}
        onAccept={(acceptedByScrolling) => {
          if (acceptedByScrolling) {
            Cookies.set(`accepted`, false);
            Cookies.set(`declined`, false);
            Cookies.set(`gatsby-gdpr-cookies-consent`, true);
            setAccepted(false);
            setHidden(false);
          } else {
            setAccepted(true);
            setHidden(true);
            Cookies.set(`accepted`, true);
            Cookies.set(`declined`, false);
            Cookies.set(`gatsby-gdpr-google-tagmanager`, true);
            initializeAndTrack(location);
          }
        }}
        visible={isVisible}
        buttonWrapperClasses="button-wrapper"
        enableDeclineButton
        disableStyles
        location="bottom"
        buttonText="Accept cookies"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-cookies-consent"
        setDeclineCookie={false}
        onDecline={() => {
          Cookies.set(`accepted`, false);
          Cookies.set(`declined`, true);
          Cookies.set(`gatsby-gdpr-cookies-consent`, false);
          setAccepted(false);
          setHidden(true);
          setVisible(`byCookieValue`);
        }}
      >
        <div className="content-text">
          We use cookies to improve your website experience and for marketing
          purposes. By accepting the cookies you consent to our privacy policy
          at:
        </div>
        <a href="/privacy" className="privacy-link" target="_blank">
          iona.fi/privacy
        </a>
      </CookieConsent>
    </CookieConsentDialogStyled>
  );

  return (
    <>
      {(Cookies.get(`gatsby-gdpr-cookies-consent`) === `false` ||
        Cookies.get(`gatsby-gdpr-cookies-consent`) === undefined) &&
        Cookies.get(`accepted`) === undefined &&
        Cookies.get(`declined`) === undefined &&
        popup()}

      {Cookies.get(`gatsby-gdpr-cookies-consent`) === `true` &&
        Cookies.get(`accepted`) === `false` &&
        Cookies.get(`declined`) === `false` &&
        popup()}
    </>
  );
};

export default CookieConsentDialog;

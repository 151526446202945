import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FONTS, COLOR } from '@/app/common/style/variables';
import { phabletAndBelow } from '@/app/common/style/mixins';
import { rem } from '@/app/common/style/functions';

const CookieConsentDialogStyled = styled.div`
  position: relative;

  .CookieConsent {
    z-index: 9999;
    position: fixed;
    width: 568px;
    height: 219px;
    background-color: ${COLOR.white};
    border-radius: 10px;
    border: 1px solid ${COLOR.palmLeaf};
    padding: 24px;
    bottom: 0;
    margin-bottom: 84px;
    left: 32px;

    ${phabletAndBelow(css`
      bottom: 0;
      height: 211px;
      width: auto;
      left: 0px;
      right: 0px;
      margin-bottom: 0px;
    `)}

    .content-text {
      font-size: ${rem(FONTS.sizeBase)};
      font-weight: ${FONTS.regular};
      line-height: 26px;
      letter-spacing: 0.2px;
      margin-bottom: 16px;
      position: relative;

      ${phabletAndBelow(css`
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        margin-bottom: 8px;
      `)}
    }

    .privacy-link {
      letter-spacing: 0.5px;
      line-height: 24px;
      font-weight: ${FONTS.medium};
      position: relative;
      text-decoration: none;
      display: block;
      margin-bottom: 24px;

      &::after {
        content: '';
        display: inline-block;
        background-image: url('/assets/images/arrow-right.svg');
        background-repeat: no-repeat;
        -webkit-background-position: right;
        background-position: right;
        width: 17px;
        height: 14px;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto 0 0 10px;
      }
    }

    .button-wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      font-family: ${FONTS.gtEestiProDisplay};
      position: relative;

      button {
        text-transform: uppercase;
        padding: 16px 32px;
        position: relative;

        ${phabletAndBelow(css`
          padding: 8px 24px;
        `)}
      }

      #rcc-decline-button {
        width: 129px;
        height: 56px;
        letter-spacing: 0.5px;

        ${phabletAndBelow(css`
          min-width: 113px;
          min-height: 40px;
          width: 113px;
          height: 40px;
        `)}
      }

      #rcc-confirm-button {
        letter-spacing: 0;
        width: 199px;
        height: 56px;
        background-color: ${COLOR.palmLeaf};
        border-color: ${COLOR.woodSmoke3};
        color: ${COLOR.white};

        ${phabletAndBelow(css`
          min-width: 183px;
          min-height: 40px;
          width: 183px;
          height: 40px;
        `)}
      }
    }
  }
`;

export default CookieConsentDialogStyled;
